.aboutme {
    margin-bottom: 20em;
    margin-top: 20em;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
}

.aboutme-title {
    text-align: center;
    margin-bottom: 2em;
}

.aboutme-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr auto;
    grid-gap: 2em;
    justify-content: stretch;
    align-items: stretch;
    justify-items: center;
}

.aboutme-contact {
    position: relative;
    max-width: 700px;
    width: 80%;
}

.aboutme-contact-card {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
        0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    border-radius: .5em;

    width: 100%;
    display: grid;

    grid-template-areas:
        'image title'
        'image details'
        'image cv';

    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    height: 100%;
}

.aboutme-contact-dots {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.aboutme-contact-dots-dot {
    background-color: var(--primary);
    border-radius: 40em;
    position: absolute;
}

.aboutme-contact-card-image {
    width: 200px;
    grid-area: image;
    margin: 3em 3em;
    justify-self: center;
    align-self: center;
    position: relative;
}

.aboutme-contact-card-image img {
    border-radius: 50em;
    overflow: hidden;
    position: relative;
}

.aboutme-contact-card-image::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform:
        translateX(10px)
        translateY(10px);
    background: var(--primary);
    content: '';
    border-radius: 40em;
    z-index: -1;
}

.aboutme-contact-card-name {
    font-size: 1.6em;
    grid-area: title;
    margin-top: 2em;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.14em;
    border-bottom: 1px solid var(--dark);
}

.aboutme-contact-card-details {
    grid-area: details;
    margin-top: 2em;

    display: grid;
    grid-template-columns: 2em 1fr;
    grid-template-rows: repeat(3, 2em);
}

.aboutme-contact-card-cv {
    grid-area: cv;
    padding-bottom: 10px;
}

.aboutme-details {
    /* align-self: center;
    position: relative;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
    margin-top: 1em; */
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2em;
}

.aboutme-details h4 {
    margin-bottom: .5em;
}

@media screen and (max-width: 1000px) {
    .aboutme-contact-dots {
        display: none;
    }

    .aboutme-contact-card {
    
        grid-template-areas:
            'image'
            'title'
            'details'
            'cv';
    
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;

        align-items: center;
        justify-items: center;
    }

    .aboutme-contact-card-name {
        font-size: 1.6em;
        margin-top: 0.5em;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 0.14em;
        border-bottom: 1px solid var(--dark);
    }
    
    .aboutme-contact-card-details {
        margin-bottom: 2.5em;
    }

}

@media screen and (max-width: 700px) {

    .aboutme-grid {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        justify-items: center;

        grid-gap: 2em;
    }

    .aboutme-contact {
        max-width: 400px;
        width: 100%;
    }

    .aboutme-details {
        width: 100%;
        max-width: 400px;
        grid-template-columns: 1fr;
        grid-gap: 2em;
    }
}
