.skills {
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    'heading heading'
    'skills erfahrungen';
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
}

.skills-title {
  grid-area: heading;
  align-self: center;
  justify-self: center;
  margin-bottom: 2.3em;
  margin-top: 2em;
}

.skills-title-mobile {
  align-self: center;
  justify-self: center;
  margin-bottom: 2.3em;
  margin-top: 2em;
  display: none;
}

.skills-skills-wrapper {
  grid-area: skills;
}

.skills-skills-more {
  margin-top: 1.5em;
  position: relative;
  font-size: 1.2em;
  margin-bottom: 2em;
  margin-left: 1em;
}

.skills-skills {
  align-self: flex-start;
  margin-right: 30px;
  min-width: 300px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
}

.skills-skills>* {
  height: 110px;
}

.skills-erfahrungen {
  grid-area: erfahrungen;
  justify-self: left;
  margin-left: 30px;
  max-width: 700px;
  font-size: 0.85em;
}

.skills-erfahrungen-year {
  margin-bottom: 20px;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

.skills-erfahrungen-year:hover .skills-erfahrungen-year-number::before {
  background: var(--secondary);

}

.skills-erfahrungen-year-number::before {
  position: absolute;
  content: '';
  left: -18px;
  top: -23px;
  height: 70px;
  width: 70px;
  border-radius: 10em;
  background: white;
  z-index: -1;
  transition: 0.3s;
}

.skills-erfahrungen-year-number {
  min-width: 70px;
  width: 70px;
  color: var(--primary);
  font-weight: 700;
  font-size: 1em;
  position: relative;
}

.skills-erfahrungen-year-entry {
  word-wrap: break-word;
  width: 100%;
}

.skills-erfahrungen-year-entry-tile {
  text-align: left;
  font-size: 1.15em;
  width: 100%;
}

.skills-erfahrungen-year-entry-tile:hover .skills-erfahrungen-year-entry-title {
  color: var(--primary);
}

.skills-erfahrungen-year-entry-title {
  font-weight: 700;
  transition: 0.3s;
  position: relative;
  border-bottom: 1px solid white;
}

.skills-erfahrungen-year-entry-tile:hover .skills-erfahrungen-year-entry-title {
  border-bottom: 1px solid var(--primary);
}

.skills-erfahrungen-year-entry-description {
  color: rgb(138, 138, 138);
  font-weight: 400;
  font-size: 0.9em;
  margin-bottom: 10px;
}

.skills-erfahrungen-popover {
  background: white;
  z-index: 10;
  padding: 1em 2em;
  
  border-radius: 1em;

  font-style: italic;

  max-width: 300px;

  --tw-shadow: 0 5px 20px -3px rgb(0 0 0 / 0.2),
  0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
  0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
  var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modal-lg {
  width: 90vw !important;
  max-width: 1300px !important;
}

.skill-detail-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-gap: 1em;
}

.skill-detail {
  text-align: left;
}

.skill-detail-title {
  font-weight: 500;
  margin-top: 1em;
}

.skill-detail-list {}

.skill-detail-list-element {
  display: grid;
  grid-template-columns: minmax(165px, 190px) 1fr;
  line-height: 1.7em;
}

.skill-detail-list-element-name {}

.skill-detail-list-element-rating {}

@media screen and (max-width: 1000px) {
  .skill-detail-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .skills {
    grid-template-areas:
      'heading1'
      'skills'
      'heading2'
      'erfahrungen';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto 1fr;

  }

  .skills-title {
    display: none;
  }

  .skills-title-mobile {
    display: unset;
  }

  .skills-title-mobile-1 {
    grid-area: heading1;
  }
  
  .skills-title-mobile-2 {
    grid-area: heading2;
  }

  .skill-detail-wrapper {
    grid-template-columns: 1fr;
  }

}