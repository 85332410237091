.details-card{
    background: white;
    z-index: 100;
    user-select: text !important;
    width: 90vw;
    height: 90vh;
    border-radius: 1em;
    overflow-y: auto;
}

.details-container {
    display: grid;
    grid-template-areas:
        'bigImage date'
        'bigImage title'
        'bigImage tags'
        'bigImage details'
        'bigImage smallimages';

    grid-template-columns: 2fr 3fr;
    grid-template-rows: auto auto auto 1fr auto;
    grid-column-gap: 2em;
    text-align: left;
    min-height: 100%;
    width: 100%;
    padding: 2em;
    overflow-y: auto;
}

.details-bigImage {
    grid-area: bigImage;
}

.details-bigImage img {
    object-fit: cover;
    height: 100%;
    min-width: 100%;
}

.details-date {
    grid-area: date;
    color: var(--gray);
    font-weight: 500;
}

.details-title {
    grid-area: title;
    margin-top: 2em;
}

.details-tags {
    grid-area: tags;
    margin-top: 0.8em;
}

.details-details {
    grid-area: details;
    margin-top: 1.5em;
    font-size: 0.9em;
}

.details-smallimages {
    margin-top: 2em;
    grid-area: smallimages;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
}
.details-smallimages img {
    object-fit: contain;
    height: 100%;
    min-width: 100%;
}

.smallimages-wide {
    grid-template-columns: 1fr;
}

.smallimages-2-1 {
    grid-template-columns: 2fr 1fr;
}

.fslightbox-loader {
    display: none !important;
}

.fslightbox-toolbar-button:nth-child(1) {
    display: none;
}

@media screen and (max-width: 1000px) {

}

@media screen and (max-width: 700px) {
    .details-container {
        grid-template-areas:
            'bigImage'
            'date'
            'title'
            'tags'
            'details'
            'smallimages';
    
        grid-template-columns: 1fr;
        grid-template-rows: auto auto auto auto auto auto auto;
        grid-column-gap: 1em;
        padding: 2em;
        text-align: left;
        height: 100%;
        width: 100%;
    }

    .details-smallimages {
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 1em;
        display: grid;
    }

    .smallimages-wide {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .smallimages-2-1 {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    
    .details-date {
        margin-top: 0.5em;
    }
    
    .details-title {
        grid-area: title;
        margin-top: 1.5em;
        font-size: 0.8em;
    }
    
    .details-tags {
        grid-area: tags;
        margin-top: 0.4em;
    }
    
    .details-details {
        grid-area: details;
        margin-top: 2em;
        font-size: 0.9em;
    }
    

}