
.moreProjects {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1em;
    padding-right: 1em;
    z-index: 100;
}

.moreProjects-title {
    text-align: center;
    margin-bottom: 2em;
}

.moreProjects-projectWrapper {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 2em;
    grid-column-gap: 1.5em;
}

.moreProjects-projectentry {
    height: 300px;
    background-size: cover;
    background-position: center;
    width: 100%;
    border-radius: .5em;
    display: grid;
    grid-template-rows: 1fr auto;
    cursor: pointer;
    overflow: hidden;
    color: white;
    position: relative;
    --tw-shadow: 0 15px 20px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

}

.moreProjects-projectentry::after { 
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background-color: rgba(0, 0, 0, 0);
    transition: all 0.3s;
}

.moreProjects-projectentry-title {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    vertical-align: bottom;
    font-size: 1.5em;
    line-height: 1em;
    padding: 0.4em 0.5em;
    font-weight: 300;
    letter-spacing: 0.06em;
    z-index: 1;
    padding-right: 1em;

    display: flex;
    align-items: flex-end;
    min-width: 200px;
}

.moreProjects-projectentry-title-company {
    font-size: 0.5em;
}

.moreProjects-projectentry-title::after {
    background: black;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: 0;
    width: 200%;
    height: 200%;
    transform: rotate(-11deg);
    z-index: -1;
}


.moreProjects-projectentry-title {
    transition: all 0.3s;
    transform: translateX(-100%);
    opacity: 0;
}

.moreProjectTags {
    display: flex;
    font-size: 0.8em;
  }
  
  .moreProjectTags-tag {
    margin-right: 10px;
    padding-right: 7px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 0.7em 0 0.7em 0;
    position: relative;
  }
  
  .moreProjectTags-tag:not(:first-child) {
    padding-left: 7px;
  }
  
  .moreProjectTags-tag:not(:last-child)::after {
    background: var(--dark);
    content: '';
    width: 0.3em;
    height: 0.3em;
    border-radius: 1em;
    position: absolute;
    top: 1em;
    right: -8px;
  }

.moreProjects-projectentry:hover .moreProjects-projectentry-title {
    transform: translateX(00px);
    opacity: 1;
}

.moreProjects-projectentry:hover::after { 
    background-color: rgba(0, 0, 0, .3);
}

@media screen and (max-width: 700px) {

    .moreProjects-projectWrapper {
        max-width: 900px;
        grid-template-columns: 1fr;
        grid-gap: 1em;
    }

}