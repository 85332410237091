.button{
    padding: 0.5em 1em;
    border-radius: 0.8em;
    position: relative;
    overflow: hidden;
    transition: all .3s;
    font-size: calc(0.8rem + .3vw);
    box-sizing: border-box;
    display: inline-block;
    background: transparent;
    z-index: 100;
    color: var(--primary);
}

.button::before {
    border: 3px solid var(--primary);
    border-radius: 0.8em;
    top: 0px;
    bottom: 0;
    left: 0;
    right: 0px;
    position: absolute;
    content: '';
}

.button::after {
    top: 0px;
    left: 0;
    bottom: 0;
    position: absolute;
    content: '';
    background: var(--primary);
    transition: all .3s;
    width: 0;
    z-index: -1;
    height: 100%;
}

.button:hover {
    color: white !important;
}

.button:hover::after {
    width: 100%;
}

button:focus, button:focus, [type="submit"]:focus {
    outline: none;
 }