.projectOuterWrapper {
  position: relative;
  height: 150vh;
  overflow: hidden;
}

.projectWrapper {
  display: grid;
  grid-template-areas:
    'number nothing1 nothgin1'
    'nothing2 text nothing3'
    'last last last';
    height: 100%;
    grid-template-columns: 150px 1fr 2fr;
    grid-template-rows: 1fr auto 2fr;
    z-index: 2;
}

.projectNumber {
  grid-area: number;
  margin-left: 50px;
  margin-top: 50px;
  font-size: 2em;
  position: relative;
  z-index: 200;
  justify-self: start;
  height: auto;
  align-self: start;
  font-weight: 300;
  color: var(--primary);
  border: 2px solid var(--primary);
  width: 2em;
  height: 2em;
  text-align: center;
  padding-top: .15em;
  border-radius: 3em;
}

.projectText {
  grid-area: text;
  height: auto;
  justify-self: start;
  background: white;
}

.projectTags {
  margin-top: 30px;
  display: flex;
  font-size: 1em;
  flex-wrap: wrap;
}

.projectImage {
  border-radius: .5em;
}

.projectTags-tag {
  margin-right: 17px;
  padding-right: 7px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 0.7em 0 0.7em 0;
  position: relative;
}


.projectTags-tag:not(:last-child)::after {
  background: var(--dark);
  content: '';
  width: 0.3em;
  height: 0.3em;
  border-radius: 1em;
  position: absolute;
  top: 1em;
  right: -8px;
}

.projectDescription {
  margin-top: 10px;
}

.projectImages {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  pointer-events: none;
  z-index: -400;
}

.projectImages > * {
  grid-column: 1;
  grid-row: 1;
  margin-left: auto;
}

.projectWrapper-custom {
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  width: 100%;
  opacity: 0;
  margin: 0;
  transform: translateY(100%);
}

.StickyText-trigger {
  margin-top: 30vh;
}

@media screen and (max-width: 700px) {
    .projectWrapper {
        grid-template-areas:
          /* 'number' */
          'text'
          'nothing';
        height: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto 1fr;
      }
      
      .projectNumber {
        margin-left: 0;
        margin-top: 0;
        padding-left: 20px;
        padding-top: 20px;
        font-size: 2em;
        margin-bottom: 0;
        padding-bottom: 5px;
        display: none;
      }

      .projectText {
        margin-top: -1px;
        margin-left: 0px;
        padding-left: 20px;
        padding-bottom: 10px;
        padding-right: 30px;
        padding-top: 10px;
        width: 100vw;
      }

      .projectImages > .small-left {
        margin-left: 30px;
      }

      .projectTags {
        margin-top: 10px;
        display: flex;
        font-size: 0.8em;
        flex-wrap: wrap;
      }

      .projectDescription {
        margin-top: 5px;
      }

}
