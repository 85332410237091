.rating-wrapper {
    font-size: 0.9em;
    white-space: nowrap;
}

.rating-item {
    margin-left: 4px;
}

.rating-selected {
    color: var(--primary);
}

.rating-de-selected {
    color: var(--secondary);
}