.hero {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
        "profilePicture heroText"
        "bottomBar bottomBar";
    grid-template-rows: 1fr auto;
    position: relative;
}

.blinkingCursor {
    animation: cursor-blink 1.5s infinite;
    /* color: ; */
    animation-delay: 3.5s;
    animation-timing-function: step-end;
}

@keyframes cursor-blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
.profilePicture {
    grid-area: profilePicture;
    max-width: 450px;
    justify-self: end;
    align-self: center;
    margin-right: 4em;
    position: relative;
    margin-left: 1em;
}

.profilePicture img {
    border-radius: 40em;
}

.profilePictureAfter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--primary);
    content: '';
    border-radius: 40em;
    z-index: -1;
}

.hero-text {
    grid-area: heroText;
    justify-self: start;
    align-self: center;
    margin-left: 4em;
}

.hero-text-title{
    font-size: 5em;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.14em;
}

.hero-text-subtitle {
    font-weight: 500;
    margin-top: 1em;
}

.bottomBar{
    grid-area: bottomBar
}

.scrollDown {
    margin-top: 20px;
    text-align: center;
    font-size: 3em;
    --animate-duration: 1.2s;
}

.hero-hubbles{
    position: absolute;
    left: 0;
    top: -100vh;
}

.hero-bubble {
    position: absolute;
    background: var(--primary);
    border-radius: 40em;
}

@media screen and (max-width: 700px) {
    .hero {
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr auto;
        grid-template-areas:
            "profilePicture"
            "heroText"
            "bottomBar";
        min-height: 80vh;
    }

    .hero-text {
        align-self: flex-start;
        justify-self: center;
        margin-left: 1em;
        margin-right: 1em;
        text-align: center;
    }
    
    .hero-text-title {
        font-size: 3em;
        font-weight: 300;
    }
    

    .profilePicture {
        margin: 50px 30px 30px 30px;
        justify-self: center;
        width: 250px;
    }

    .profilePictureAfter {
        margin-top: 10px;
        margin-left: 10px;
        width: 100%;
        height: 100%;
    }

    .hero-bubbles-wrapper {
        display: none;
    }
  }
