@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

:root {
  --primary: #005b76;
  --secondary: #d9eef4;
  --dark: #1b2c31;
  --gray: rgb(158, 158, 158);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden;
  color: var(--dark);
}

h1, h2, h4 {
  /* font-family: 'Fredericka the Great', cursive; */
}

h1 {
  font-size: calc(2em + 0.75vw);
  font-weight: 300;
  line-height: 1em;
}

h2 {
  font-size: calc(1.6em + 0.75vw);;
  font-weight: 500;
  line-height: 1em;
}

h4 {
  font-size: calc(1em + 0.5vw);;
  font-weight: 700;
}

::-moz-selection { /* Code for Firefox */
  color: var(--primary);
  background: var(--secondary);
}

::selection {
  color: var(--primary);
  background: var(--secondary);
}