.footer {
    border-top: 2px solid var(--primary);
    /* height: 4em; */
}

.footer-title {
    color: var(--primary);
    text-align: center;
    font-size: 1em;
    padding: 1em;
    font-weight: 700;
}

.footer-modal-content {
    text-align: left;
}

.footer-modal-content > * {
    margin-bottom: 5px;
}