.skill-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.skill-image-wrapper {
    border-radius: 10em;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skill-image {
    max-width: 40px;
    height: auto;
    max-height: 40px;
}

.skill-title {
    font-weight: 500;
    margin-top: 5px;
    font-size: 0.9em;
    text-align: center;
}